@import "../../theme.scss";

.searching-for-label {
  margin-bottom: $spacing-md;
}

.query-label {
  color: $primary-color;
  font-weight: bold;
}

.no-results {
  color: $secondary-color;
  display: flex;
  min-height: 250px;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
}
