@import "../../theme.scss";
$border-color: #e1e1e1;

.container {
  border: solid 1px $border-color;
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
}

.image {
  height: 180px;
  width: 100%;
  object-fit: cover;
  display: block;
  object-position: center;
}

.info-container {
  background-color: $tertiary-color;
  color: $primary-color;
  padding: ($spacing-md * 1.5) $spacing-md * 2;
  font-size: 0.9em;
}

.title-label,
.location-label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.title-label {
  margin-bottom: $spacing-md;
}

.location-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $secondary-color;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.location-pin {
  display: inline-block;
  margin-right: $spacing-sm;
}
