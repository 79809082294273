.search-container {
  width: 400px;
  transition: width 300ms;
}

@media (max-width: 800px) {
  .search-container {
    width: 100%;
  }
}
