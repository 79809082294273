@import "../../../theme.scss";

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: $spacing-lg;
}

@media (min-width: 901px) and (max-width: 1100px) {
  .container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 801px) and (max-width: 900px) {
  .container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 601px) and (max-width: 800px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .container {
    grid-template-columns: 1fr;
  }
}
