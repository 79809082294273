@import "../../../theme.scss";
.outer-container {
  position: relative;
  overflow: hidden;
}

.inner-container {
  display: flex;
  flex-direction: row;
  position: relative;
  transition: right 400ms;
}

.slide {
  $width: 420px;
  flex: auto;
  flex-shrink: 0;
  width: $width;
  max-width: $width;
  padding-right: $spacing-lg;
  box-sizing: border-box;
}

$button-width: 100px;

.next-button,
.previous-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  width: $button-width;
  z-index: 2;
  animation: fade-in 1s;
  animation-fill-mode: forwards;
  cursor: pointer;
  user-select: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.next-button {
  right: 0;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
}

.previous-button {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
}

@media (max-width: 600px) {
  .slide {
    $width: 100%;
    width: $width;
    max-width: $width;
    padding-right: 0;
  }
}
