/* --- Colors --- */
$primary-color: #2f516d;
$secondary-color: #899bb2;
$tertiary-color: rgb(234, 237, 243);
$highlight-color: rgb(149, 196, 235);
$background-light-color: #ffffff;

/* --- Spacing --- */
$spacing-sm: 4px;
$spacing-md: $spacing-sm * 2;
$spacing-lg: $spacing-md * 3;
