@import "../../../../theme.scss";

.input {
  padding: $spacing-md;
  outline: none;
  border: solid 1px;
  border-color: $secondary-color;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    border-color: $highlight-color;
  }
}
