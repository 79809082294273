@import "../../../theme.scss";

.light {
  background-color: $background-light-color;
}

.transparent {
  background: none;
}

.well--all {
  padding: $spacing-lg;
}

.well--vertical {
  padding: $spacing-lg 0;
}

.well--horizontal {
  padding: 0 $spacing-lg;
}

.well--top {
  padding-top: $spacing-lg;
}

.well--none {
  padding: 0;
}
